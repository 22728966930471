<template>
    <div class="register">
        <van-nav-bar
            title="加入"
            fixed
            left-arrow
            @click-left="$router.push({name:'home'})"
        />

        <div class="panel">
            <div class="title">短信登录</div>
            <div class="ipt-wrapper username">
                <input class="ipt" type="number" name="username" ref="username" v-model="username" oninput="if(value.length>11)value=value.slice(0,11)" placeholder="手机号码" />
            </div>
            <div class="ipt-wrapper sms">
                <input class="ipt" type="number" oninput="if(value.length>4)value=value.slice(0,4)" name="sms" ref="sms" v-model="sms" placeholder="验证码" />
                <span v-if="waitSec<=0" class="color-blue" @click="getCode">获取验证码</span>
                <div v-else class="color-gray">
                    <span>{{ waitSec }}秒后可重新发送</span>
                </div>
            </div>
            <van-button block type="info" class="login-btn" :class="disBtn?'dis-btn':''" @click="login">立即登录</van-button>
            <div class="color-gray2 agree">
                <van-checkbox class="agree-checkbox" v-model="agree" />
                <div>勾选同意<span class="color-blue" @click="$router.push({name:'agree',params:{t:'user'}})">《用户协议》</span>和<span class="color-blue" @click="$router.push({name:'agree',params:{t:'private'}})">《隐私协议》</span></div>
            </div>
        </div>
      </div>
</template>
<script>
import { Toast } from 'vant';
export default {
    name: 'Register',
    data(){
        return {
            referer: this.$route.params.i || '',
            email: '',
            password: '',
            otp: '10086',
            waitSec: 60,
            sendWait: false,
            disableRefererIpt: true,
            loading: false,
            waitItvl: null,

            agree: false,
            sms: '',
            username: '',
            itvl: {}
        }
    },
    components:{
    },
    mounted(){

    },
    methods:{
        submit(){
            this.loading = true;
            let _this = this;
            if(!this.email || !this.password || !this.otp){
                Toast('输入有误');
                return;
            }
            let data = {
                email: this.email,
                referer: this.referer,
                password: this.password,
                otp: this.otp
            };
            this.$http.call(this.$http.api.register,{data:data}).then(function(res){
                let resp = res.data;
                if(resp.code=='200'){
                    _this.$store.commit('setToken', resp.data.token);
                    _this.$store.commit('setUserinfo', resp.data.user);
                    _this.$router.push({name:'home'});
                }else{
                    let _msg = _this.$t('register.tips.' + resp.data);
                    _this.$message.error(_msg);
                }
            },function(res){
                console.log('something goes wrong...');
                console.log(res);
            }).then(()=>{
                _this.loading = false;
            });
        },
        goLogin(){
            this.$router.push('/login');
        },
        getMailOtp(){
            if(this.sendWait){
                return;
            }
            let _reg = /^([a-zA-Z\d][\w-_\\.]{2,})@(\w{2,})\\.([a-z]{2,})(\\.[a-z]{2,})?$/;
            if(!this.email || !_reg.test(this.email)){
                this.$message.error(_this.$t('register.tips.emailError'));
                return;
            }
            let data = {
                email: this.email
            };
            if(this.loading) return;
            this.loading = true;
            let _this = this;
            this.$http.call(this.$http.api.mailOtp,{
                data
            }).then((res)=>{
                let resp = res.data;
                if(resp.code == '200'){
                    _this.$message.success(_this.$t('register.sendSuccess'));
                    _this.sendWait = true;
                    _this.waitItvl = setInterval(()=>{
                        if(_this.waitSec == 0){
                            clearInterval(_this.waitItvl);
                            _this.sendWait = false;
                            _this.waitSec = 60;
                        }else{
                            _this.waitSec -= 1;
                        }
                    },1000)
                }else{
                    let _k = _this.$t('register.tips.'+resp.data);
                    _this.$message.error(_k);
                }
            },(res)=>{
                console.log('something goes wrong...');
                console.log(res);
            }).then(()=>{
                this.loading = false;
            });
        }
    }
}
</script>
<style lang="less">
.register{
    width: 100%;
    color: #333;
    padding-top: 100px;
    font-size: 14px;
    .panel {
        margin: 50px 30px;
        .title {
            text-align: left;
            font-weight: bold;
            padding-bottom: 15px;
        }
        .ipt-wrapper {
            margin-bottom: 35px;
            background: #fefefe;
            border: 1px solid #f1f1f1;
            border-radius: 4px;
            padding: 10px;
            text-align: left;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .ipt {
                flex: 1;
                outline: none;
                border: none;
                background: transparent;
            }
        }
        .login-btn {
            border-radius: 4px;
        }
        .dis-btn {
            opacity: .7;
        }
        .agree {
            padding: 15px 0;
            font-size: 13px;
            display: flex;
            height: 20px; 
            line-height: 20px;
            .agree-checkbox {
                padding-right: 4px;
            }
        }
    } 
}
</style>
